import {useIntl} from "react-intl";
import {ErrorMessage, Field, useFormikContext} from "formik";
import {cities, civilStatus, estractos, nivelEstudios, typeVivienda} from "../../../__mocks__/data";
import { useEffect , useState} from "react";
import {useRequestContext} from "../../../hooks/useRequestContext";
import './applicant-info.scss';
import {renderTypeIdentificationDescription} from "../../../utils/utils";
import { FormSpouse } from "./ModalSpouse/FormSpouse";
import _ from "lodash";
import { AdminService } from "../../../services";
import moment from "moment";

interface FormPersonalInfoProps {
    errors?: any;
    values?: any;
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}

export function FormPersonalInfo(props: FormPersonalInfoProps) {
    const {request} = useRequestContext();

    const { values: valuesFormik } = useFormikContext();
    const {errors, values,setPopup} = props;
    const intl = useIntl();
    const [modal, setModal] = useState(false);
    const [activelugar, setactive] = useState(false);
    const [fechaexp, setactive2] = useState(false);
    const [ciudades, setCiudades] = useState(cities as any);
    const [ciudades2, setCiudades2] = useState(cities as any);
    const [EstadosCivil, setEstadosCivil] = useState([]as any);
    const [tipovivi, setTipoVivienda] = useState([] as any);
    const [NivelEsti, setNivelEstudio] = useState([] as any);
    const [departamentos, setDepartamentos] = useState(cities as any);
    const [allCities, setAllCiudades] = useState(cities as any);
    const [telefonoMovil, settelefonoMovil] = useState(cities as any);
    const [telefonoMovil2, settelefonoMovil2] = useState(cities as any);
    const [one] = useState(null);
    const Pws_Estado_Civil = (valuesFormik as any).Pws_Estado_Civil as string;

  

    const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCode = event.target.value;
        const selectedCity = ciudades?.ListCiudad?.Datos_Ciudad.find(
            (item: any) => item.s_codigo === selectedCode
        );

        if (selectedCity) {
            localStorage.setItem("selectedCity", selectedCity.s_nombre);
           ;
        }
    };

    useEffect(() => {
        console.log("Valores actuales:", values);

        if (values.Pws_Ciudad_res) {
            const selectedCity = ciudades?.ListCiudad?.Datos_Ciudad.find(
                (item: any) => item.s_codigo === values.Pws_Ciudad_res
            );

            if (selectedCity) {
                localStorage.setItem("selectedCity", selectedCity.s_nombre);
                console.log("Ciudad seleccionada guardada en localStorage:", selectedCity.s_nombre);
            }
        }
    }, [values.Pws_Ciudad_res, ciudades]); // Se ejecuta cuando cambia Pws_Ciudad_res o la lista de ciudades

    useEffect(() => {
        if (Pws_Estado_Civil === "1" || Pws_Estado_Civil === "3" ) {
            setModal(!modal);
        }
    }, [Pws_Estado_Civil]);
    
    useEffect(()=>{
        allData()
        AdminService.getAllCiudades().then((Datos:any)=>{
            setAllCiudades(Datos.payload.data)
            console.log(request);
            
            if(!request.searchANI){
                if( request.personCygnus?.Pws_Lugar_exp){ 
                    values.Pws_Lugar_exp=request.personCygnus?.Pws_Lugar_exp
                    setactive(true)
                }else{
                    setactive(true)
                }
            }else{
                if(request.personANI?.municipioExpedicion){
                    setactive(true)
                values.Pws_Lugar_exp= _.filter( Datos.payload.data.ListCiudad.Datos_Ciudad, 
                    { s_nivel: '3',s_nombre: request.personANI?.municipioExpedicion}).length>0?
                    _.filter( Datos.payload.data.ListCiudad.Datos_Ciudad, 
                        {s_nivel: '3', s_nombre: request.personANI?.municipioExpedicion})[0].s_codigo:""
                }else{
                    setactive(true)

                }
            }
            if(!request.searchANI){
                if( request.personCygnus?.Pws_Fec_expe){
                    setactive2(false)
                }else{
                    
                    setactive2(true)

                }
            }else{
                if(request.personANI?.fechaExpedicion){
                    /* values.Pws_Fec_expe=moment(new Date(request.personANI?.fechaExpedicion)).format('yyyy-MM-DD') */
                    values.Pws_Fec_expe= request.personANI?.fechaExpedicion
                    setactive2(false)
                }else{
                    setactive2(true)

                }
            }
        })


    },[one])
    useEffect(()=>{
       if(allCities){
        
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        
        
        let citiesDepent2 = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '2'});
        setDepartamentos(citiesDepent)
        citiesDepent2.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'});
        setCiudades2(citiesDepent2);
        settelefonoMovil(values.Pws_tel_emp1)
        settelefonoMovil2(values.Pws_tel_emp2)
   
    }
    },[allCities])
    const allData= async ()=>{
        const estadosCvil= await AdminService.getEstadosCiviles();
        if(estadosCvil.payload.data.listEstCivil.Datos_EstCivil.length>0 && 
            estadosCvil.payload.data.listEstCivil.Datos_EstCivil[0].s_codigo > 0){

                setEstadosCivil(estadosCvil.payload.data.listEstCivil.Datos_EstCivil)
        }else{
            let estadocivil = [
                {s_codigo: "C",s_descripcion: "CASADO"},
                {s_codigo: "S",s_descripcion: "SOLTERO"},
                {s_codigo: "U",s_descripcion: "UNION LIBRE"},
                {s_codigo: "V",s_descripcion: "VIUDO"},
                {s_codigo: "P",s_descripcion: "SEPARADO"},
                {s_codigo: "D",s_descripcion: "DIVORCIADO"}
            ]
            setEstadosCivil(estadocivil)
        }
        const tipovivienda= await AdminService.gettipovivienda();
        if(tipovivienda.payload.data.tip_viv.datos_tip_vivi.length>0 
            && tipovivienda.payload.data.tip_viv.datos_tip_vivi[0].c_descripcion !="Error de Conexion" 
            && tipovivienda.payload.data.tip_viv.datos_tip_vivi[0].i_codigo>0 ){
            setTipoVivienda(tipovivienda.payload.data.tip_viv.datos_tip_vivi)
        }else{
            let vivienda = [
                {i_codigo: "1", c_descripcion: "CASA PROPIA"},
                {i_codigo: "2", c_descripcion: "FAMILIAR"},
                {i_codigo: "3", c_descripcion: "ARRIENDO"},
                {i_codigo: "4", c_descripcion: "MAYORDOMO"},
                {i_codigo: "5", c_descripcion: "COMODATO"},
            ]
            setTipoVivienda(vivienda);
        } 
        const nivelEstudi= await AdminService.getNivelEstudio();
        if(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio.length>0 
            && nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio[0].c_descripcion !="Error de Conexion" &&
            nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio[0].s_codigo>0 ){
            setNivelEstudio(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio)
        }else{
            let estudios =[
                {s_codigo: '1', s_descripcion: 'PRIMARIA'},
                {s_codigo: '2', s_descripcion: 'UNIVERSITARIO'},
                {s_codigo: '3', s_descripcion: 'TECNICO - TECNOLOGO'},
                {s_codigo: '4', s_descripcion: 'BACHILLER'},
                {s_codigo: '5', s_descripcion: 'NINGUNO'}
            ]
            setNivelEstudio(estudios)
        }

        console.log(EstadosCivil);
        
    }
    const toggle = () => setModal(!modal);

     const handleCities = (e: { target: { value: string; }; }) => {
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, 
            { s_nivel: '3', s_ubicacion: e.target.value });
        setCiudades(citiesDepent);
    }

    return (
        <div className="row container-border-app">
            <div className="row" >
                <div className="text-left">
                    
                <div className="col" style={{"textAlign":"left"}}>
                     <h3 >Información del solicitante</h3>
                </div>
                </div>
            </div>
            <div className="row">
          
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage(
                            {id: "placeholder_field_names"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {!request.searchANI ? request.personCygnus?.Pws_Nombres : request.personANI?.primerNombre + " " + request.personANI?.segundoNombre}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage(
                            {id: "placeholder_field_last_names"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {!request.searchANI ? request.personCygnus?.Pws_Apellidos1 + ' ' + request.personCygnus?.Pws_Apellidos2 : request.personANI?.primerApellido + " " + request.personANI?.segundoApellido}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage(
                            {id: "placeholder_identification_type"})}</div>
                    <div
                        className={"label-fields-peps-mobile-ligth display-flex"}>
                        {!request.searchANI ? renderTypeIdentificationDescription(request.personCygnus?.Pws_Tip_Identificacion!) : renderTypeIdentificationDescription(values.Pws_Tip_Identificacion!)}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage(
                            {id: "placeholder_identification"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {!request.searchANI ? request.personCygnus?.Pws_Identificacion : request.personANI?.nuip}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage(
                            {id: "placeholder_expedition_date"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                    {fechaexp?<>
                        <Field id={"Pws_Fec_expe"}
                            name={"Pws_Fec_expe"}
                            type={"date"}
                            className={`${errors.Pws_Fec_expe ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder={'MM/dd/YYYY'}/>
                           
                        </>:<>
                        {!request.searchANI ? request.personCygnus?.Pws_Fec_expe.split("-00.")[0] : request.personANI?.fechaExpedicion.split("-00.")[0]}
                        </>}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_expedition_place"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {activelugar?<>
                            <Field as="select"
                               className={`${errors.Pws_Lugar_exp ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Lugar_exp"
                        >
                            <option>Lugar Expedicion</option>
                            {ciudades2 && ciudades2.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                        </>:<>
                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_codigo:(!request.searchANI ? request.personCygnus?.Pws_Lugar_exp: values.Pws_Lugar_exp)}).length>0?_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_codigo:(!request.searchANI ? request.personCygnus?.Pws_Lugar_exp: values.Pws_Lugar_exp)})[0].s_nombre:""}
                        </>}
                 </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage(
                            {id: "placeholder_date_birth"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        <div className="display-flex">
                            <Field id={"Pws_Fec_nacime"}
                                name={"Pws_Fec_nacime"}
                                type={"date"}
                                className={`${errors.Pws_Fec_nacime ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                                placeholder={'MM/dd/YYYY'}/>
                            <div className="label-fields-peps-warning">
                                <ErrorMessage name="Pws_Fec_nacime"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_marital_status"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Estado_Civil ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Estado_Civil"
                        >
                            <option>{intl.formatMessage({id: "placeholder_marital_status"})}</option>
                            {EstadosCivil && EstadosCivil.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                                )
                            })}
                        </Field>
                        <div className="label-fields-peps-warning">
                            <ErrorMessage name="Pws_Estado_Civil"/>
                            <ErrorMessage name="Pws_Con_Tip_identif"/>
                            <ErrorMessage name="Pws_Con_identif"/>
                            <ErrorMessage name="Pws_Con_nomsol"/>
                            <ErrorMessage name="Pws_Con_tel"/>
                            <ErrorMessage name="Pws_Direccion_res" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "residence_address"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_Direccion_res"}
                               name={"Pws_Direccion_res"}
                               className={`${errors.Pws_Direccion_res ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               placeholder={intl.formatMessage({ id: "residence_address" })} />
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "department"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Departamento ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Departamento"
                                onClick={handleCities}
                        >
                            <option>{intl.formatMessage({id: "department"})}</option>
                            {departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                    </div>

                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</div>

                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Ciudad_res ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Ciudad_res"
                            //    onChange={handleCityChange}
                        >
                            <option>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</option>
                            {ciudades && ciudades.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mobile_phone_1"})}</div>
                    <div
                        className={"label-fields-peps-mobile-ligth display-flex"}>
                        <input type="text" className="form-know-you-item-input-xxs display-flex" value={values.Pws_Telefono1} />
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mobile_phone_2"})}</div>
                    <div
                        className={"label-fields-peps-mobile-ligth display-flex"}>
                        <input type="Text" className="form-know-you-item-input-xxs display-flex" value={values.Pws_Telefono2} />    
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mail"})}</div>
                    <div
                        className={"label-fields-peps-mobile-ligth display-flex"}>
                            <input type="Text" className="form-know-you-item-input-xxs display-flex" value={values.Pws_Correo} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "gender"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                                className={`${errors.Pws_Genero ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Genero">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            <option value={'F'}>{'Femenino'}</option>
                            <option value={'M'}>{'Masculino'}</option>
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_social_stratum"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                            className={`${errors.Pws_Estrato_per ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Estrato_per">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key: number) => {
                                return (
                                    <option key={key} value={item.i_codigo}>{item.c_descripcion}</option>
                                )
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_number_of_people_in_charge"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Per_cargo ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Per_cargo">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            <option value={'0'}>{'0'}</option>
                            <option value={'1'}>{'1'}</option>
                            <option value={'2'}>{'2'}</option>
                            <option value={'3'}>{'3'}</option>
                            <option value={'4'}>{'4'}</option>
                            <option value={'5'}>{'5'}</option>
                            <option value={'6'}>{'6'}</option>
                            <option value={'7'}>{'7'}</option>
                            <option value={'8'}>{'8'}</option>
                            <option value={'9'}>{'9'}</option>
                            <option value={'10'}>{'10'}</option>
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_type_of_housing"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Tip_vivienda ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Tip_vivienda">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            {tipovivi && tipovivi.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.i_codigo}>{item.c_descripcion}</option>
                                )
                            })}
                        </Field>
                    </div>

                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_educational_level"})}</div>
                    <div className="display-flex"><Field as="select"
                        className={`${errors.Pws_Niv_estudio ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                        name="Pws_Niv_estudio">
                        <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                        {NivelEsti && NivelEsti.map((item:any, key: number) => {
                            return (
                                <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                            )
                        })}
                    </Field>
                    </div>

                </div>
                <div className="col"/>
                <FormSpouse values={values} errors={errors} show={modal} onHide={toggle}/>
              
            </div>
        </div>
    )
}